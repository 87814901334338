import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LightLogo = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
        lightlogo: file(relativePath: { eq: "light_logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 78) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.lightlogo.childImageSharp.fluid } alt="Light HAG Logo"
    />
}

export default LightLogo